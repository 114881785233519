import React, { Component } from 'react'
import {Link,Redirect} from "react-router-dom";
import {checkRight,checkCategoryRight} from "../../GeneralFunctions/general"
import ReactJsAlert from "reactjs-alert"
import { Helmet } from 'react-helmet';
const IDLE_TIMEOUT = 30 * 60 * 1000; // 30 minutes
const WARNING_TIMEOUT = 29*60 * 1000; // 29 minute
const WARNING_SESSION_OUT = 54*60 * 1000; // 54 minute
const SESSION_OUT = 55*60 * 1000; // 55 minute
export class Aside extends Component {
    constructor(props) {
            super(props);
        
            this.state = {
            alertStatus :false, alertType :"error", alertTitle:null,
            isLoggedIn: true, // Example state for the user's login status
            showWarning: false, // Flag to show the warning
            showWarningSession:false,
            isSessionOut:false
            };
        
            this.idleTimeoutId = null;
            this.warningTimeoutId = null;
            this.warningSessionout = null;
            this.animationFrameId = null;
            this.sessionout = null;
            this.throttledResetIdleTimeout = this.createThrottledResetIdleTimeout();

        }
    
        componentDidMount() {
            if(process.env.REACT_APP_MODE ==="production"){
                this.setupIdleTimeout();
                this.addEventListeners();
            }
        }
        
        componentWillUnmount() {
            if(process.env.REACT_APP_MODE ==="production"){
                clearTimeout(this.idleTimeoutId);
                clearTimeout(this.warningTimeoutId);
                this.removeEventListeners();
            }
        }

        createThrottledResetIdleTimeout = () => {
            if(process.env.REACT_APP_MODE ==="production"){
                let isThrottled = false;
            
                return () => {
                    if (!isThrottled) {
                        isThrottled = true;
                
                        setTimeout(() => {
                        this.resetIdleTimeout();
                        isThrottled = false;
                        }, 1000); // Throttle duration (1 second in this example)
                    }
                
                };
            }
        };
    
        setupIdleTimeout = () => {
            this.idleTimeoutId = setTimeout(() => {
            this.setState({ isLoggedIn: false }); // Log out the user or perform any desired action
            // You can also display a modal or show a notification before logging out the user
            // Example: Alert.alert('Idle Timeout', 'You have been logged out due to inactivity.');
            }, IDLE_TIMEOUT);
        
            this.warningTimeoutId = setTimeout(() => {
            this.setState({ showWarning: true }); // Show the warning
            }, WARNING_TIMEOUT);

            this.warningSessionout = setTimeout(() => {
                this.setState({ showWarningSession: true }); // Show the warning
            }, WARNING_SESSION_OUT);

            this.sessionout = setTimeout(() => {
                this.setState({ isSessionOut: true }); // Show the warning
            }, SESSION_OUT);

        };

        resetIdleTimeout = () => {
            this.setState({showWarning:false})
            clearTimeout(this.idleTimeoutId);
            clearTimeout(this.warningTimeoutId);
            this.setupIdleTimeout();
        };
            
        addEventListeners = () => {
            document.addEventListener('mousemove', this.throttledResetIdleTimeout);
            document.addEventListener('keydown', this.throttledResetIdleTimeout);
        };
            
        removeEventListeners = () => {
            document.removeEventListener('mousemove', this.throttledResetIdleTimeout);
            document.removeEventListener('keydown', this.throttledResetIdleTimeout);
        };
    
    render() {
        const { isLoggedIn,showWarning,showWarningSession,isSessionOut } = this.state;
        const userData = JSON.parse(window.localStorage.getItem('userData'))
        return (
            <div>
                
              

                {isLoggedIn===false && (
                <div>
                        <Redirect to="/login" />
                </div>
                )}
                {showWarning===true && (
                <div>
                    <ReactJsAlert autoCloseIn ={600000} status={true} type={"warning"} title={"Are you still there? Please click 'Okay' to stay logged in"} Close={() => this.setState({ showWarning: false })}/>
                </div>
                )}

                {showWarningSession && (
                <div>
                    <ReactJsAlert autoCloseIn ={600000} status={true} type={"warning"} title={"The system will log you out in a minute. Please consider logging out to reset the session"} Close={() => this.setState({ showWarningSession: false })}/>
                </div>
                )}

                {isSessionOut && (
                <div>
                    <Redirect to="/login" />                
                </div>
                )}

                <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                    <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/dashboard">
                        <div className="sidebar-brand-icon rotate-n-15">
                        <i className="fas fa-warehouse"></i>
                        </div>
                    <div className="sidebar-brand-text mx-3">{userData.branchName}</div>
                    </Link>
                    <hr className="sidebar-divider my-0"/>

                    <li className="nav-item ">
                        <Link to="/dashboard" className="nav-link" >
                        <i className="fas fa-fw fa-tachometer-alt"></i>
                        <span>Dashboard</span></Link>
                    </li>
                    <hr className="sidebar-divider"/>
                    {/**Settings */}
                    {checkCategoryRight(1)===true?<div>
                        <li className="nav-item">
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo101" aria-expanded="true" aria-controls="collapseTwo101">
                            <i className="fa fa-cog"></i>
                            <span>Settings</span>
                            </a>
                            <div id="collapseTwo101" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                   
                                    {checkRight(1)===true?<Link to="/systemSettings" className="collapse-item">System Settings</Link>:null}
                                    {/*checkRight(2)===true?<Link to="/renewalSettings" className="collapse-item">Loan Renewal Settings</Link>:null*/}
                                
                                    {checkRight(3)===true?<Link to="/addAccount" className="collapse-item">Add Account Type</Link>:null}
                                    {checkRight(4)===true?<Link to="/seeAccounts" className="collapse-item">See Account Types</Link>:null}
                                    {checkRight(13)===true?<Link to="/addLoanType" className="collapse-item">Set Loan Type</Link>:null}
                                    {checkRight(15)===true?<Link to="/addAssets" className="collapse-item">Set Club Assets</Link>:null}
                                    
                                </div>
                            </div>
                        </li>
                        <hr className="sidebar-divider"/>
                    </div>:null}

                     {/**Staff Management */}
                     {checkCategoryRight(7)===true?<div>
                        <li className="nav-item">
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo1015" aria-expanded="true" aria-controls="collapseTwo101">
                            <i className="fas fa-users"></i>
                            <span>Member Management</span>
                            </a>
                            <div id="collapseTwo1015" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    {checkRight(60)===true?<Link to="/add_users" className="collapse-item">Add Member</Link>:null}
                                    {checkRight(112)===true?<Link to="/activate_users" className="collapse-item">Activate/Deactivate Member</Link>:null}
                                    {checkRight(61)===true?<Link to="/see_users" className="collapse-item">View Member</Link>:null}
                                </div>
                            </div>
                        </li>
                        <hr className="sidebar-divider"/>
                    </div>:null}

                     {/**Approvals */}
                     {checkCategoryRight(13)===true?<div>
                        <li className="nav-item">
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo101z" aria-expanded="true" aria-controls="collapseTwo101z">
                            <i className="fa fa-cog"></i>
                            <span>Approvals</span>
                            </a>
                            <div id="collapseTwo101z" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    {checkRight(113)===true?<Link to="/approveFine" className="collapse-item">Approve/Reject Fine Pay</Link>:null}
                                    {checkRight(114)===true?<Link to="/approveMonthPay" className="collapse-item">Approve/Reject Month Pay</Link>:null}
                                    {checkRight(115)===true?<Link to="/approveWelfarePay" className="collapse-item">Approve/Reject Welfare Pay</Link>:null}
                                    {checkRight(116)===true?<Link to="/approveLoan" className="collapse-item">Approve/Reject Loan Request</Link>:null}
                                    {checkRight(117)===true?<Link to="/approveLoanPay" className="collapse-item">Approve/Reject Loan Pay</Link>:null}

                                </div>
                            </div>
                        </li>
                        <hr className="sidebar-divider"/>
                    </div>:null}

                    {/**Payment Management */}
                    {checkCategoryRight(5)===true?<div>
                                            <li className="nav-item">
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo1014pay" aria-expanded="true" aria-controls="collapseTwo101">
                            <i className="fa fa-calendar"></i>
                            <span>Payment Management</span>
                            </a>
                            <div id="collapseTwo1014pay" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                <Link to="/addPayment" className="collapse-item">Make Payment</Link>
                                <Link to="/seeAllPayments" className="collapse-item">See Payments</Link>

                                </div>
                            </div>
                        </li>
                        <hr className="sidebar-divider"/>
                    </div>:null}

                    {/**Loan Management */}
                    {checkCategoryRight(5)===true?<div>
                        <li className="nav-item">
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo1014" aria-expanded="true" aria-controls="collapseTwo101">
                            <i className="fa fa-calendar"></i>
                            <span>Loan Management</span>
                            </a>
                            <div id="collapseTwo1014" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    {/*}
                                    {checkRight(104)===true?<Link to="/seeLoanRequest" className="collapse-item">View Loan Requests</Link>:null}
                                    {checkRight(42)===true?<Link to="/active_loans" className="collapse-item">View Active Loans</Link>:null}
                                    {checkRight(81)===true?<Link to="/givenout_loans" className="collapse-item">View Given Out Loans</Link>:null}                  
                                    {checkRight(43)===true?<Link to="/closed_loans" className="collapse-item">View Closed Loans</Link>:null}
                                    {checkRight(44)===true?<Link to="/loan_payments" className="collapse-item">View Loan Payments</Link>:null}
                                    {checkRight(45)===true?<Link to="/edited_payments" className="collapse-item">View Edited Payments</Link>:null}
                                    {checkRight(46)===true?<Link to="/soon_to_expire" className="collapse-item">View Soon To Expire</Link>:null}
                                    {checkRight(47)===true?<Link to="/expired_loans" className="collapse-item">View Expired Loans</Link>:null}
                                    {checkRight(48)===true?<Link to="/renewal_requests" className="collapse-item">View Renewal Requests</Link>:null}
                                    {checkRight(50)===true?<Link to="/renewed_loans" className="collapse-item">View Renewed Loans</Link>:null}
                                    {checkRight(51)===true?<Link to="/defaulted_loans" className="collapse-item">View Defaulters</Link>:null}
                    {checkRight(57)===true?<Link to="/seeFines" className="collapse-item">View Fines</Link>:null}*/}
                                    {/*
                                    {checkRight(42)===true?<Link to="/active_loans" className="collapse-item">View Active Loans</Link>:null}
                                    {checkRight(81)===true?<Link to="/givenout_loans" className="collapse-item">View Given Out Loans</Link>:null}                  
                                    {checkRight(43)===true?<Link to="/closed_loans" className="collapse-item">View Closed Loans</Link>:null}
                                    {checkRight(44)===true?<Link to="/loan_payments" className="collapse-item">View Loan Payments</Link>:null}
                                    {checkRight(46)===true?<Link to="#" className="collapse-item">View Soon To Expire</Link>:null}
                                    {checkRight(47)===true?<Link to="#" className="collapse-item">View Expired Loans</Link>:null}
                                    {checkRight(57)===true?<Link to="#" className="collapse-item">View Fines</Link>:null}*/}

                                    <Link to="/addAllLoan" className="collapse-item">Add Loan</Link>
                                    <Link to="/seeAllLoans" className="collapse-item">See Loans</Link>

                                    {checkRight(42)===true?<Link to="#" className="collapse-item">View Active Loans</Link>:null}
                                    {checkRight(81)===true?<Link to="#" className="collapse-item">View Given Out Loans</Link>:null}                  
                                    {checkRight(43)===true?<Link to="#" className="collapse-item">View Closed Loans</Link>:null}
                                    {checkRight(44)===true?<Link to="#" className="collapse-item">View Loan Payments</Link>:null}
                                    {checkRight(46)===true?<Link to="#" className="collapse-item">View Soon To Expire</Link>:null}
                                    {checkRight(47)===true?<Link to="#" className="collapse-item">View Expired Loans</Link>:null}
                                    {checkRight(57)===true?<Link to="#" className="collapse-item">View Loan Fines</Link>:null}
                                    <Link to="/seeloanReport" className="collapse-item">See Member's Loan Report</Link>
                                    
                                    
                                </div>
                            </div>
                        </li>
                        <hr className="sidebar-divider"/>
                    </div>:null}

                    {/**Fine Management */}
                    {checkCategoryRight(5)===true?<div>
                        <li className="nav-item">
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo1014fine" aria-expanded="true" aria-controls="collapseTwo101">
                            <i className="fa fa-calendar"></i>
                            <span>Fine Management</span>
                            </a>
                            <div id="collapseTwo1014fine" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                <Link to="/addFines" className="collapse-item">Add Fine</Link>
                                <Link to="/seeAllFines" className="collapse-item">See Fines</Link>  
                                </div>
                            </div>
                        </li>
                        <hr className="sidebar-divider"/>
                    </div>:null}


                    {/**Expense Management */}
                    {checkCategoryRight(8)===true?<div>
                        <li className="nav-item">
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo1" aria-expanded="true" aria-controls="collapseTwo1">
                            <i className="fa fa-credit-card"></i>
                            <span>Expense Management</span>
                            </a>
                            <div id="collapseTwo1" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                {checkRight(63)===true?<Link to="expenseCategory" className="collapse-item">Expense Category</Link>:null}
                                {checkRight(65)===true?<Link to="addExpense" className="collapse-item">Add Expense</Link>:null}
                                {checkRight(74)===true?<Link to="seeExpense" className="collapse-item">View Expense</Link>:null}
                                </div>
                            </div>
                        </li>
                        <hr className="sidebar-divider"/>
                    </div>:null}

                    {/**report */}
                    {checkCategoryRight(10)===true?<div>
                        <li className="nav-item">
                            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                            <i className="far fa-file-alt"></i>
                            <span>Report Management</span>
                            </a>
                            <div id="collapse6" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <Link to="/seeloanReport" className="collapse-item">See Member's Loan Report</Link>
                                    <Link to="/getmemberReport" className="collapse-item">See Member Report</Link>
                                    <Link to="/monthlyReport" className="collapse-item">See Monthly Report</Link>
                                    <Link to="/currentReport" className="collapse-item">See Current Report</Link>
                                    {/*{checkRight(98)===true?<Link to="staffClientList" className="collapse-item">Staff Client Report</Link>:null}
                                    {checkRight(69)===true?<Link to="generateStaffReport" className="collapse-item">Staff Daily Report</Link>:null}
                                    {checkRight(69)===true?<Link to="generateStaffGeneralReport" className="collapse-item">Staff General Report</Link>:null}
                                    {checkRight(92)===true?<Link to="staffPerformanceReport" className="collapse-item">Staff Performance Report</Link>:null}
                                    {checkRight(70)===true?<Link to="dailyReport" className="collapse-item">Daily Report</Link>:null}
                                    {checkRight(71)===true?<Link to="generalReport" className="collapse-item">General Report</Link>:null}
                                    {/*checkRight(72)===true?<Link to="#" className="collapse-item">Targets Report</Link>:null*}
                                     {checkRight(112)===true?<Link to="expensesReport" className="collapse-item">Expenses Report</Link>:null}
                                    */}
                                    {/*{checkRight(112)===true?<Link to="/seeExpense" className="collapse-item">Expenses Report</Link>:null}
                                    {checkRight(71)===true?<Link to="/clubGeneralReport" className="collapse-item">Club General Report</Link>:null}
                                    {checkRight(117)===true?<Link to="/memberReport" className="collapse-item">Member Report</Link>:null}*/}

                                    {checkRight(112)===true?<Link to="/seeExpense" className="collapse-item">Expenses Report</Link>:null}

                                    
                                </div>
                            </div>
                        </li>
                        <hr className="sidebar-divider"/>
                    </div>:null}

                    
                    <div className="text-center d-none d-md-inline">
                        <button className="rounded-circle border-0" id="sidebarToggle"></button>
                    </div>

                </ul>
            </div>
        )
    }
}

export default (Aside)
