import React, { Component } from 'react';
import {connect } from 'react-redux';
import {getMainData,getData} from "../../Redux/ducks/mainReducer";
import {Alert,Label,Row,Col,FormGroup,Input, Button,Spinner,Table} from 'reactstrap'

import ReactJsAlert from "reactjs-alert"
export class CurrentReport extends Component {
    state={
        isSaving:false,
        mainData:null,
        msg:null,
        addCatMsg:null,
        btnDisabled:false,
        errorMsg:null,
        successMsg:null,
    
        alertStatus :false, alertType :"error", alertTitle:null,
        qty:"",total:"",cost:"",details:"",staff:"",cat:"",name:"",myDate:"",
        reportData:null

    }

    componentDidMount(){

        const data = new FormData();
        this.props.getData(data,"GET_CURRENTREPORT")

    }

    componentDidUpdate(prevProps){
        const{mainData,msg,mainDataStatus,addUserStatus,getReportData} = this.props;
        
        if(mainDataStatus !== prevProps.mainDataStatus){
            if(mainDataStatus===true){
                this.setState({isSaving:false,mainData:mainData
                })
            }
        } 

        if(addUserStatus !== prevProps.addUserStatus){
            if(addUserStatus===true){
                this.setState({isSaving:false,successMsg:msg,btnDisabled:false,reportData:getReportData})

            }else if(addUserStatus===false){
                this.setState({isSaving:false,erroMsg:msg,btnDisabled:false,
                    alertStatus :true, alertType :"error", alertTitle:msg})
            }
        } 
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    render() {
        const {reportData} = this.state
        let catList = [];
        let staffDataList = []
        const myUserData =  this.state.mainData
       
        let openData = []
        let gotData=[]
        let paymentData = []
        
        let myNo=0
        let topenLoan = 0
        let topenContribution = 0
        let topenFines = 0
        let topenWelfare = 0
        let topenIntrest = 0
        let texpIntrest = 0
    
        if(reportData !==null){
            reportData.map((values,i) =>{
                myNo = myNo+1

                topenLoan = topenLoan+parseInt(values.openLoan)
                topenContribution = topenContribution+parseInt(values.openContribution)
                topenFines = topenFines+parseInt(values.openFines)
                topenWelfare = topenWelfare+parseInt(values.openWelfare)
                topenIntrest = topenIntrest+parseInt(values.openIntrest)
               
            

                openData.push(
                    <tr key={i}>
                        <th scope="row">{myNo}</th>
                        <td>{values.name}</td>
                        <td>{parseInt(values.openLoan).toLocaleString()}</td>
                        <td>{parseInt(values.openIntrest).toLocaleString()}</td>
                        
                        <td>{parseInt(values.openContribution).toLocaleString()}</td>
                        <td>{parseInt(values.openFines).toLocaleString()}</td>
                        <td>{parseInt(values.openWelfare).toLocaleString()}</td>
                        <td>{
                            (parseInt(values.openLoan)+
                            parseInt(values.openIntrest)+parseInt(values.openContribution)+
                            parseInt(values.openFines)+parseInt(values.openWelfare)).toLocaleString()
                            
                            }</td>
                    </tr>
                )

             

            })

            openData.push(
                <tr className='tableTotals'>
                    <th scope="row">{""}</th>
                    <td>{"TOTALS"}</td>
                    <td>{parseInt(topenLoan).toLocaleString()}</td>
                   
                    <td>{parseInt(topenIntrest).toLocaleString()}</td>
                    <td>{parseInt(topenContribution).toLocaleString()}</td>
                    <td>{parseInt(topenFines).toLocaleString()}</td>
                    <td>{parseInt(topenWelfare).toLocaleString()}</td>
                    <td>{
                        (parseInt(topenLoan)+
                        parseInt(topenIntrest)+parseInt(topenContribution)+
                        parseInt(topenFines)+parseInt(topenWelfare)).toLocaleString()
                        
                        }</td>
                </tr>
            )

        }
        return (
            <div>
                <div>
                    <Row className="mainWrraper">
                        <div className="block-header">
                            <h4>SEE CURRENT REPORT </h4>
                        </div>
                    <Col xs="12" md="12" className="tableWrapper" >
                      
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                       
                        <div className='table-container '>
                            <br/><br/>
                            <h6></h6>
                            <Table striped responsive>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Loan</th>
                                    
                                        <th>Intrest</th>
                                        <th>Subscription</th>
                                        <th>Fines</th>
                                        <th>Welfare</th>
                                        <th>TOTAL</th>
                                    </tr>
                                </thead>

                                {this.state.isLoading?
                                <tbody>
                                    <tr>
                                        <td colSpan="8">
                                            <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                        </td>
                                    </tr>
                                </tbody>:
                                
                                <tbody>
                                    {openData}
                                </tbody>}
                            </Table>
                          
                        </div>
                    </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    mainDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    mainData : state.mainReducer.mainData,
    getReportData : state.mainReducer.getMyData,
    addUserStatus:state.mainReducer.getMyDataStatus,
});
export default connect(
    mapStateToProps, {getMainData,getData}
)(CurrentReport)
